import { IconSizes } from '../constants/iconSizes'
import { ButtonSizes } from './../constants/buttonSizes'

export const buttonSizesToIconSize: { [k in ButtonSizes]: IconSizes } = {
    [ButtonSizes.ICON_S]: IconSizes.XS,
    [ButtonSizes.ICON_M]: IconSizes.M,
    [ButtonSizes.ICON_L]: IconSizes.M,

    // should not use
    [ButtonSizes.AUTO_SIZE]: IconSizes.S,
    [ButtonSizes.TEXT_M]: IconSizes.S,
    [ButtonSizes.TEXT_L]: IconSizes.S,
}
