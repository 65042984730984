<script lang="ts" setup>
import { PropType, Ref } from 'vue'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { modifiersConfig } from '~~/src/config/Modifiers'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { EmitsEnum } from '~~/src/constants/emits'
import { IconSizes } from '~~/src/constants/iconSizes'
import { useToggle } from '~~/src/hooks/useToggle'
import { ModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'

const props = defineProps({
	availableModifiers: { type: Object as PropType<ModifierType[]>, required: true },
})
const emit = defineEmits([EmitsEnum.Cancel])

const { availableModifiers } = toRefs(props)
const { toggleValue, toggle } = useToggle(true)
const deleteQueue: Ref<ModifierType[]> = ref([])
const loadTimeMs = 5000

const getIcon = (type: ModifierType) => {
	return modifiersConfig[type].icon
}
const getText = (type: ModifierType) => {
	return modifiersConfig[type].text
}

const onDelete = (modifier: ModifierType) => deleteQueue.value.push(modifier)
const onDeleteCancel = (modifier: ModifierType) =>
	(deleteQueue.value = deleteQueue.value.filter((el) => el != modifier))
const modifierBeingCancelled = (modifier: ModifierType) => deleteQueue.value.includes(modifier)
const deleteFunctionToUse = computed(
	() => (modifier: ModifierType) => (modifierBeingCancelled(modifier) ? onDeleteCancel(modifier) : onDelete(modifier))
)

const onDeleteConfirmed = (modifier: ModifierType) => {
	emit(EmitsEnum.Cancel, modifier)
}
const canCancel = computed(() => (modifier: ModifierType) => availableModifiers.value.some((el) => el === modifier))
</script>

<template>
	<div class="flex flex-col truncate rounded-md">
		<div class="align-center flex cursor-pointer justify-between bg-yellow-300 p-3" @click="toggle">
			<CommonText :text="TranslationKeys.REMOVE_MODIFIERS" />
			<CommonIcon
				class="mr-1 transition-all duration-300 ease-out"
				:icon-name="SpSvg.BasicCarretDown"
				:icon-size="IconSizes.XS"
				:class="toggleValue ? 'rotate-180' : ''"
			/>
		</div>
		<AnimationCollapse>
			<div v-if="toggleValue" class="flex flex-col">
				<!-- this v-for / v-if is here otherwise a bug is generated due to re-rendering -->
				<template v-for="mod in ModifierType">
					<div
						v-if="canCancel(mod)"
						class="relative flex items-center justify-between border-t border-t-white bg-yellow-200 p-3"
					>
						<div class="z-10 flex gap-2">
							<CommonIcon :icon-name="getIcon(mod)" :icon-size="IconSizes.XS" />
							<CommonText :text="getText(mod)" />
						</div>
						<CommonButton
							class="z-10 mr-1"
							:type="ButtonTypes.GHOST"
							:size="ButtonSizes.ICON_S"
							@click="deleteFunctionToUse(mod)"
						>
							<CommonIcon :icon-name="SpSvg.BasicTrash" :icon-size="IconSizes.XS" />
						</CommonButton>
						<AnimationFillRight :enter-animation-ms="loadTimeMs">
							<LoadingBarContent
								v-if="modifierBeingCancelled(mod)"
								:loading-time-ms="loadTimeMs"
								class="absolute left-0 top-0 h-full w-full"
								@complete="onDeleteConfirmed(mod)"
							/>
						</AnimationFillRight>
					</div>
				</template>
			</div>
		</AnimationCollapse>
	</div>
</template>
