import { lightFormat } from 'date-fns'
import { trackingConfig } from '../config/TrackingConfig'
import { useAccommodationsStore } from '../store/accommodations'
import { ModifierType } from '../submodules/sharedTypes/common/Modifiers'
import { Range } from '../submodules/sharedTypes/common/Range'
import { utilDate } from '../utils/utilDate'
import { utilModifiers } from '../utils/utilModifiers'
import { TweakModifierType } from './../submodules/sharedTypes/common/Modifiers'

const getAccommodationCountries = (roomTypeIds: number[]) => {
	const accommodationsStore = useAccommodationsStore()
	const roomTypes = roomTypeIds.map((roomTypeId) => accommodationsStore.getRoomTypeById(roomTypeId))
	const accommodations = roomTypes.map((roomType) =>
		accommodationsStore.getAccommodationById(roomType?.accommodationId)
	)
	return accommodations.map((accommodation) => accommodation?.location?.country)
}

export const composeSingleModeTrackingMessage = (
	dateRange: Range<Date>,
	dateFilter: number[],
	ids: number[],
	modifiersMap: Map<ModifierType, number>
) => {
	return {
		bulk_price_change_acc_country: getAccommodationCountries(ids),
		start_changed_date: lightFormat(dateRange.from, 'yyyy-MM-dd'),
		end_changed_date: lightFormat(dateRange.to, 'yyyy-MM-dd'),
		days_date_interval_num: utilDate.daysDifference(dateRange),
		days_selected_num: utilDate.daysDifference(dateRange),
		days_of_the_week: utilDate.weekDaysNamesInRange(trackingConfig.trackingLanguage, dateRange, dateFilter),
		selected_rooms: ids.length,
		customization_type: 'single_room',
		frozen_price_num: modifiersMap.get(ModifierType.Frozen),
		max_price_num: modifiersMap.get(ModifierType.Max),
		min_price_num: modifiersMap.get(ModifierType.Min),
		tweak_price_num: modifiersMap.get(ModifierType.Tweak),
	}
}

export const composeDraggingModeTrackingMessage = (
	dateRange: Range<Date>,
	dateFilter: number[],
	ids: number[],
	modifiersMap: Map<ModifierType, number>
) => {
	return {
		bulk_price_change_acc_country: getAccommodationCountries(ids),
		start_changed_date: lightFormat(dateRange.from, 'yyyy-MM-dd'),
		end_changed_date: lightFormat(dateRange.to, 'yyyy-MM-dd'),
		days_date_interval_num: utilDate.daysDifference(dateRange),
		days_selected_num: utilDate.daysDifference(dateRange),
		days_of_the_week: utilDate.weekDaysNamesInRange(trackingConfig.trackingLanguage, dateRange, dateFilter),
		selected_rooms: ids.length,
		customization_type: 'draggable',
		frozen_price_num: modifiersMap.get(ModifierType.Frozen),
		max_price_num: modifiersMap.get(ModifierType.Max),
		min_price_num: modifiersMap.get(ModifierType.Min),
		tweak_price_num: modifiersMap.get(ModifierType.Tweak),
	}
}

export const composeMultipleModeTrackingMessage = (
	dateRange: Range<Date>,
	dateFilter: number[],
	ids: number[],
	modifiersMap: Map<ModifierType, number>,
	tweak?: TweakModifierType
) => {
	return {
		bulk_price_change_acc_country: getAccommodationCountries(ids),
		start_changed_date: lightFormat(dateRange.from, 'yyyy-MM-dd'),
		end_changed_date: lightFormat(dateRange.to, 'yyyy-MM-dd'),
		days_date_interval_num: utilDate.daysDifference(dateRange),
		days_selected_num: utilDate.daysDifference(dateRange),
		days_of_the_week: utilDate.weekDaysNamesInRange(trackingConfig.trackingLanguage, dateRange, dateFilter),
		selected_rooms: ids.length,
		customization_type: 'multiple_rooms',
		frozen_price_num: modifiersMap.get(ModifierType.Frozen),
		max_price_num: modifiersMap.get(ModifierType.Max),
		min_price_num: modifiersMap.get(ModifierType.Min),
		tweak_price_num: modifiersMap.get(ModifierType.Tweak),
		tweak_type: utilModifiers.tweakToTrackingType(tweak),
	}
}
