import { defineStore } from 'pinia'
import { IdPopup } from '../constants/IdPopup'

export interface IRestrictedRect {
    x: number
    y: number
    width: number
}

type ModalsState = {
    popups: {
        addedAt: string
        id: IdPopup
        associatedRect: IRestrictedRect
        props: any
        backgroundColor: string
        borderColor: string
        borderWidth: string
        alignTriangle: boolean
    }[]
}

export const usePopupStore = defineStore('🚀 popup store', {
    state: (): ModalsState => ({
        popups: [],
    }),
    actions: {
        addPopup(
            id: IdPopup,
            associatedRect: IRestrictedRect,
            props: any,
            backgroundColor: string = 'bg-white',
            borderColor: string = 'border-transparent',
            borderWidth: string = 'border-0',
            alignTriangle: boolean = false
        ): void {
            associatedRect.y += window.scrollY
            this.popups.push({
                addedAt: new Date().toISOString(),
                id,
                associatedRect,
                props,
                backgroundColor,
                borderColor,
                borderWidth,
                alignTriangle,
            })
        },
        addUniquePopup(
            id: IdPopup,
            associatedRect: IRestrictedRect,
            props: any,
            backgroundColor: string = 'bg-white',
            borderColor: string = 'border-transparent',
            borderWidth: string = 'border-0',
            alignTriangle: boolean = false
        ): void {
            if (this.popups.some((el) => el.id === id)) {
                return
            }

            associatedRect.y += window.scrollY
            this.popups.push({
                addedAt: new Date().toISOString(),
                id,
                associatedRect,
                props,
                backgroundColor,
                borderColor,
                borderWidth,
                alignTriangle,
            })
        },
        closeTopPopup(): void {
            this.popups.pop()
        },
    },
})
