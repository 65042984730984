<script lang="ts" setup>
import { PropType } from 'vue'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { Localizable } from '~/types/Localizable'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { buttonSizesToIconSize } from '~~/src/config/ButtonSizesToIconSizes'
import { iconButtonConfig } from '~~/src/config/IconButton'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { EmitsEnum } from '~~/src/constants/emits'
import { useButtonConfig } from '~~/src/hooks/useButtonConfig'
import { useRipple } from '~~/src/hooks/useRipple'

const props = defineProps({
	size: { type: String as PropType<ButtonSizes>, required: true },
	type: { type: String as PropType<ButtonTypes>, default: '' },
	selected: { type: Boolean, default: false },
	icon: { type: String as PropType<SpSvg>, required: true },
	tooltip: { type: String as PropType<TranslationKey | Localizable>, required: true },
	disabled: Boolean,
	dataId: String,
	dataTestid: { type: String, required: false },
	rippleColorOverride: { type: String, required: false },
})
const { size, type, selected, disabled, icon, tooltip, dataId, rippleColorOverride, dataTestid } = toRefs(props)
const emit = defineEmits([EmitsEnum.Click])
const handleClick = function (event: MouseEvent) {
	if (!disabled.value) {
		triggerRipple(event)
	}
	emit(EmitsEnum.Click)
}
const { colors, sizes, ripple } = useButtonConfig(type, size, selected, disabled)
const rippleColor = computed(() => rippleColorOverride?.value || ripple.value)
const target = ref()
const { triggerRipple } = useRipple(target, rippleColor)

const iconSize = computed(() => buttonSizesToIconSize[size.value])

let showTimeout: any = undefined
const showTooltip = ref(false)
const debounceShow = () =>
	(showTimeout = setTimeout(() => {
		if (!disabled.value) {
			showTooltip.value = true
		}
	}, iconButtonConfig.tooltipDebounceTime))
const debounceHide = () => {
	showTooltip.value = false
	if (showTimeout != undefined) {
		clearTimeout(showTimeout)
	}
}

// if the button is disabled, hide the tooltip
watch(disabled, debounceHide)
</script>

<template>
	<CommonTooltip :text="tooltip" :show="showTooltip" width="w-fit">
		<button
			:class="`${colors} ${sizes} relative flex items-center justify-center overflow-hidden truncate rounded-md`"
			:data-testid="dataTestid"
			@click.stop="handleClick"
			:selected="selected"
			:disabled="disabled"
			@mouseenter="debounceShow"
			@mouseleave="debounceHide"
			:data-id="dataId"
			ref="target"
		>
			<CommonIcon :icon-name="icon" :icon-size="iconSize" />
		</button>
	</CommonTooltip>
</template>
