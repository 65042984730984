<script setup lang="ts">
import { PropType } from 'vue'
import { SpSvg } from '@/autogen/SpSvg'
import { messageCardTypes } from '@/config/messageCardTypes'
import { ButtonSizes } from '@/constants/buttonSizes'
import { ButtonTypes } from '@/constants/buttonTypes'
import { EmitsEnum } from '@/constants/emits'
import { IconSizes } from '@/constants/iconSizes'
import { IMessageCardData } from '@/types/IMessageCardData'

const props = defineProps({
	cardData: { type: Object as PropType<IMessageCardData>, required: true },
	inlineLink: { type: Boolean, default: false },
	shadow: { type: String, default: 'shadow-high-contrast' },
})
const { cardData, inlineLink, shadow } = toRefs(props)
const cardConfig = computed(() => messageCardTypes[cardData.value.cardType])
const onLinkClick = () => {
	if (cardData.value.link != undefined) {
		cardData.value.link()
	}
}

const emit = defineEmits<{
	(event: EmitsEnum.Close, closeCallback?: Function): void
}>()

const onClose = () => emit(EmitsEnum.Close, props.cardData.closeCallback)
</script>

<template>
	<div :class="`flex flex-row truncate rounded-md ${cardConfig.mainColor} ${shadow}`">
		<!-- accent rectangle -->
		<div :class="`w-1.5 ${cardConfig.accentColor}`"></div>

		<div class="relative flex justify-between w-full gap-6 px-10 py-4" :class="inlineLink ? 'flex-row' : 'flex-col'">
			<!-- close icon -->
			<div class="absolute right-4 top-4" style="right:18px, transform: translateY(18px); margin-top: -4px;">
				<CommonButton @click="onClose" :type="ButtonTypes.GHOST" :size="ButtonSizes.ICON_S">
					<CommonIcon v-if="cardData.canClose" :icon-name="SpSvg.BasicTimes" :icon-size="IconSizes.XS" />
				</CommonButton>
			</div>

			<div class="flex flex-col gap-1">
				<div class="absolute" style="margin-top: -4px">
					<CommonIcon
						:icon-name="cardConfig.icon"
						:fill="cardConfig.iconColor"
						:icon-size="IconSizes.S"
						class="adjust-text-sm"
					/>
				</div>
				<!-- title -->
				<CommonText :text="$t(cardData.title, cardData?.titleReplacements ?? [])" class="font-semibold" />

				<!-- text -->
				<div v-if="cardData.message != undefined" class="whitespace-normal">
					<CommonText
						:text="$t(cardData.message, cardData?.messageReplacements ?? [])"
					/>
				</div>
			</div>

			<!-- additional link -->
			<CommonLink v-if="cardData.linkText" @click="onLinkClick" :text="cardData.linkText" />
		</div>
	</div>
</template>

<style scoped>
.adjust-text-sm {
	/* 
    x is translated by 28px because:
    - 12px are the padding
    - 16px is the icon's size

    y is translated by 1px because:
    the text would otherwise look slightly misalligned
     */
	transform: translate(-28px, 1px);
}
</style>
