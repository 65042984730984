import { Ref } from 'vue'

export function useEllipsisCheck(elementRef: Ref<Element | null>) {
    const hasEllipsis = computed(() => {
        if (elementRef.value) {
            const element = elementRef.value as HTMLElement
            return (
                element != undefined &&
                element.scrollWidth > element.clientWidth
            )
        }
    })

    return { hasEllipsis }
}
