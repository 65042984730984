<script lang="ts" setup>
import { PropType } from 'vue'
import { EmitsEnum } from '~~/src/constants/emits'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { Modifiers, ModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'
import ModifiersEditor from '@/components/modifiers/Editor.vue'
import { TestIds } from '~/constants/TestIds'

const props = defineProps({
	modifiers: { type: Object as PropType<Modifiers>, default: {} },
	modifiersFilters: {
		type: Object as PropType<ModifierType[]>,
		default: Array.from(Object.values(ModifierType)) as ModifierType[],
	},
	removableModifiers: {
		type: Object as PropType<ModifierType[]>,
		default: [ModifierType.Frozen, ModifierType.Max, ModifierType.Min, ModifierType.Tweak],
	},
	disabledModifiers: {
		type: Object as PropType<ModifierType[]>,
		default: [],
	},
	currency: { type: Object as PropType<Currency>, required: false },
	associatedModifiers: { type: Object as PropType<Modifiers[]>, default: [] },
	isCompact: { type: Boolean, default: isMobile() },
	testIds: {
		type: Object as PropType<{
			container?: string
			'tweak-left-button'?: string
			'tweak-right-button'?: string
			'remover-modifier-container'?: string
			'filters-container'?: string
		}>,
		default: () => ({}),
	},
})
const { modifiers, modifiersFilters, removableModifiers, currency, associatedModifiers, isCompact, testIds } =
	toRefs(props)
const emit = defineEmits([EmitsEnum.Change, EmitsEnum.Cancel, EmitsEnum.ErrorStatusChange])

const onModifiersChange = (modifiers: Modifiers) => emit(EmitsEnum.Change, modifiers)
const onModifierRemove = (modifier: ModifierType) => emit(EmitsEnum.Cancel, modifier)
const onErrorStatusChange = (hasError: boolean) => emit(EmitsEnum.ErrorStatusChange, hasError)

const modifierEditorReference = ref<InstanceType<typeof ModifiersEditor>>()
const resetModifiers = () => {
	modifierEditorReference.value?.resetModifiers()
}
defineExpose({
	resetModifiers,
})

const { isMobile: isMobileViewport } = useMobileInfos()
const isEditorCompact = ref(false)
watch(
	isMobileViewport,
	() => {
		isEditorCompact.value = isMobileViewport.value
	},
	{ immediate: true }
)
</script>

<template>
	<div class="flex flex-col gap-4" :style="isEditorCompact ? '' : 'min-width:500px'" :data-testid="testIds?.container">
		<ModifiersRemover
			v-if="removableModifiers != undefined && removableModifiers.length > 0"
			:available-modifiers="removableModifiers"
			:data-testid="testIds?.['remover-modifier-container']"
			@cancel="onModifierRemove"
		/>

		<ModifiersEditor
			ref="modifierEditorReference"
			:modifiers="modifiers"
			@change="onModifiersChange"
			:data-testid="testIds?.['filters-container']"
			:test-ids="{
				'tweak-left-button': testIds?.['tweak-left-button'],
				'tweak-right-button': testIds?.['tweak-right-button'],
			}"
			:modifiers-filters="modifiersFilters"
			:currency="currency"
			:associated-modifiers="associatedModifiers"
			:disabled-modifiers="disabledModifiers"
			@error-status-change="onErrorStatusChange"
			:is-compact="isEditorCompact"
		/>
	</div>
</template>
