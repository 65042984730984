<script lang="ts" setup>
import { EmitsEnum } from '~~/src/constants/emits';

const props = defineProps({
    loadingTimeMs: { type: Number, default: 5000 },
    color: { type: String, default: 'bg-yellow-400' }
})
const emit = defineEmits([EmitsEnum.Cancel, EmitsEnum.Complete])

const tick = ref(false)
nextTick(() => tick.value = true)

setTimeout(() => {
    emit(EmitsEnum.Complete)
}, props.loadingTimeMs);
</script>

<template>
    <div v-if="tick" :class="props.color"></div>
</template>