
<script lang="ts" setup>
import { PropType } from 'vue';
import { utilCurrency } from '~/utils/utilCurrency';
import { inputFieldConfig } from '~~/src/constants/inputField';
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency';
import { NumericVariationType } from '~~/src/submodules/sharedTypes/common/NumericVariationType';
import { IndexedPrice } from '~~/src/submodules/sharedTypes/common/Price';
import { utilJustifiers } from '~~/src/utils/utilJustifiers';

const props = defineProps({
    currency: { type: Object as PropType<Currency>, required: true },
    linkedRoom: { type: Object as PropType<IndexedPrice>, required: true }
})
const { currency, linkedRoom } = toRefs(props)

const inputField = ref()
const onFocus = () => inputField.value.select()

// const getBackground = computed(() => isActive.value ? modifiersConfig[modifierType.value].activeBackground : 'bg-white')
const valueToDisplay = computed(() => utilJustifiers.readableLinkedRoomModifier(linkedRoom.value.linkedRoomType, currency.value, true))
const additionalSign = computed(() => linkedRoom.value.linkedRoomType.variationType == NumericVariationType.Amount
    ? utilCurrency.currentCurrency(currency.value)
    : "%")
</script>

<template>
    <div class="relative flex items-center gap-2 truncate bg-dark-blue-100">
        <input type="text"
            class="w-full h-full text-sm text-right appearance-none text-md text-dark-blue-400 bg-dark-blue-100"
            :value="valueToDisplay" ref="inputField" :placeholder="inputFieldConfig.numericPlaceholder" @focusin="onFocus"
            :disabled="true" />
        <span class="text-sm text-dark-blue-400">
            {{ additionalSign }}</span>
    </div>
</template>