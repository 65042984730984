<script lang="ts" setup>

const props = defineProps({
    enterAnimationMs: { type: Number, default: 500 },
    exitAnimationMs: { type: Number, default: 500 },
})
const { enterAnimationMs, exitAnimationMs } = toRefs(props)
</script>

<template>
    <Transition name="fill"
        :style="` --enter-animation-time: ${enterAnimationMs}ms; --exit-animation-time: ${exitAnimationMs}ms;`">
        <slot></slot>
    </Transition>
</template>

<style>
.fill-enter-active {
    transition: width var(--enter-animation-time) ease;
}

.fill-leave-active {
    transition: width var(--exit-animation-time) ease;
}

.fill-enter-from,
.fill-leave-to {
    width: 0;
}

.fill-leave-from,
.fill-enter-to {
    width: 100;
}
</style>