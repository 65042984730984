<script lang="ts" setup>
import { PropType } from 'vue'
import { utilCurrency } from '~/utils/utilCurrency'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { modifiersConfig } from '~~/src/config/Modifiers'
import { EmitsEnum } from '~~/src/constants/emits'
import { IconSizes } from '~~/src/constants/iconSizes'
import { inputFieldConfig } from '~~/src/constants/inputField'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { ModifierType } from '~~/src/submodules/sharedTypes/common/Modifiers'

const props = defineProps({
	currentValue: { type: Number },
	modifierType: { type: String as PropType<ModifierType>, required: true },
	isActive: { type: Boolean, default: false },
	currency: { type: Object as PropType<Currency>, required: false },
	hasError: { type: Boolean, default: false },
	isDisabled: { type: Boolean, default: false },
})
const { currentValue, modifierType, isActive, currency, hasError } = toRefs(props)

const emit = defineEmits([EmitsEnum.Change, EmitsEnum.Delete])
const onChange = (event: any) => {
	if (event.target.value == inputFieldConfig.numericPlaceholder) {
		return
	}
	const val = parseInt(event.target.value)
	if (!Number.isNaN(val) && val >= 0) {
		emit(EmitsEnum.Change, val)
	} else {
		emit(EmitsEnum.Delete)
	}
}

const inputField = ref()
const onFocus = () => inputField.value.select()

const getBackground = computed(() =>
	isActive.value ? modifiersConfig[modifierType.value].activeBackground : 'bg-white'
)
const valueToDisplay = computed(() => currentValue?.value?.toString())
const currencyIconComputedClass = computed(() =>
	isActive.value ? modifiersConfig[modifierType.value].activeCurrencyIconColor : 'text-dark-blue-400'
)
</script>

<template>
	<div class="relative flex items-center gap-2 truncate" :class="getBackground">
		<input
			type="number"
			min="0"
			:disabled="isDisabled"
			class="text-md h-full w-full appearance-none text-right text-sm placeholder:text-dark-blue-400"
			@input.stop="onChange"
			:value="valueToDisplay"
			ref="inputField"
			:class="getBackground"
			:placeholder="inputFieldConfig.numericPlaceholder"
			@focusin="onFocus"
		/>
		<span class="text-sm" :class="currencyIconComputedClass">
			{{ currency ? utilCurrency.currentCurrency(currency) : '-' }}</span
		>
		<CommonIcon v-if="hasError" :icon-name="SpSvg.BasicErrorCircle" :icon-size="IconSizes.XS" class="fill-red-500" />
	</div>
</template>
